import { CheckIcon } from "@heroicons/react/solid";
import clsx from "clsx";

export const DaysComponent = ({ progress }) => {
  return (
    <div className="flex space-x-3 lg:space-x-6">
      {["M", "T", "W", "T", "F", "S", "S"].map((day, i) => {
        // progress is a number between 0 and 1
        // we want to tell whether the progress is greater than the current day
        const isActive = progress > i / 7;
        const isNext = progress + 0.13 > i / 7;
        return (
          <div
            className={clsx(
              "flex items-center border lg:text-base text-sm justify-center w-8 h-8 lg:w-10 lg:h-10 rounded-full cursor-default",
              {
                "bg-charcoal text-white": !isActive,
                "bg-volt text-black p-2 border-volt": isActive,
                "border-volt": isNext || isActive,
                "border-charcoal": !isNext && !isActive,
              }
            )}
            key={i}
          >
            {isActive ? <CheckIcon className="w-6 h-6 lg:w-8 lg:h-8" /> : day}
          </div>
        );
      })}
    </div>
  );
};
