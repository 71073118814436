import { Action } from "~/components/action";
import { Text } from "~/components/text-node";

export const ShopPromo = () => {
  return (
    <>
      <div className="flex-col px-4 pt-8 pb-8 space-y-4 text-center lg:max-w-md xl:pr-0 lg:px-8 xl:pl-16 lg:py-28 center lg:justify-start lg:items-start lg:text-left">
        <img className="h-6" src="/assets/logo/black.svg" alt="ladder logo" />
        <h1 className="text-6xl text-black heading">
          <Text at="home.shopAd.title" />
        </h1>
        <p className="pb-8 text-lg lg:pb-4 text-darkGray md:max-w-lg">
          <Text at="home.shopAd.description" />
        </p>
        <Action
          at="home.shopAd.cta"
          className="px-10 py-4 text-white bg-black rounded-full cta"
        />
      </div>
      <div className="self-end">
        <img
          src="https://media.teams.joinladder.com/website/coaches/shop.png"
          alt="ladder coaches"
          className="w-full h-full transform scale-110 -translate-y-3 lg:scale-100 lg:-translate-y-0 lg:translate-x-8 xl:translate-x-16"
        />
      </div>
    </>
  );
};
