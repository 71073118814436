import { Text } from "~/components/text-node";
import { Action } from "~/components/action";
import { useIsMobile } from "~/lib/use-breakpoint";

export const PaymentTeaser = () => {
  const isMobile = useIsMobile();
  return (
    <div className="relative z-10 w-full pt-32 pb-32 lg:pb-56 bg-basic">
      <div className="container flex flex-col items-center justify-center mx-auto">
        <div className="w-full h-full px-4 mb-16 bg-center bg-no-repeat bg-contain lg:mb-0">
          <h2 className="mb-12 text-4xl text-center text-black uppercase lg:mb-0 lg:text-left lg:text-6xl heading">
            <Text at="home.payment.title" />
          </h2>
          <img
            className="w-full h-full px-2 transform bg-center bg-no-repeat bg-contain lg:-mt-16 scale-118 lg:scale-100"
            src={`https://media.teams.joinladder.com/website/cards/${
              isMobile ? "mobile" : "desktop"
            }.png`}
            alt="ladder app"
          />
        </div>
        <Action
          at="home.payment.cta"
          className="block px-10 py-4 mx-auto text-white bg-black rounded-full cta lg:mx-0"
        />
      </div>
    </div>
  );
};
