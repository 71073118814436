import { useEffect, useRef, useState } from "react";
import { useIsomorphicLayoutEffect } from "react-use";

export function useCountdown(duration, onEnd, end = 0) {
  const [countDown, setCountDown] = useState(duration);
  let interval = useRef();

  useEffect(() => {
    interval.current = setInterval(() => {
      setCountDown((current) => current - 1);
    }, 1000);

    return () => clearInterval(interval.current);
  }, [onEnd]);

  useEffect(() => {
    if (countDown !== end) return;
    if (typeof onEnd === "function") return onEnd();
    setCountDown(duration);
  }, [duration, countDown, onEnd, end]);

  return {
    countDown,
  };
}

export const useIOSSafeHeight = () => {
  useIsomorphicLayoutEffect(() => {
    if (typeof window === "undefined") return;
    document.documentElement.style.setProperty(
      "--vh",
      window.innerHeight * 0.01 + "px"
    );
  }, []);

  return `calc(var(--vh, 1vh) * 100)`;
};
