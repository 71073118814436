import clsx from "clsx";
import {
  motion,
  useMotionTemplate,
  useTransform,
  useViewportScroll,
} from "framer-motion";
import React from "react";
import useInView from "react-cool-inview";
import { useVideo } from "react-use";
import { Action } from "~/components/action";
import { PhoneOutline } from "~/components/phone-outline";
import { accessVariant, Text } from "~/components/text-node";
import { VideoPlayer } from "~/components/workout/player/video-player";
import { useCountdown } from "~/hooks/misc";
import { useIsMobile } from "~/lib/use-breakpoint";
import { useApp } from "~/state-containers/app";
import { useExperiment } from "~/state-containers/experiment";
import { fancyTimeFormat } from "../../workout/helpers";

const Countdown = React.memo(() => {
  const { countDown } = useCountdown(60 * 30);

  return (
    <span className="font-display center text-3xl" key={countDown}>
      {fancyTimeFormat(countDown)}
    </span>
  );
});

const AnimatedPhone = ({ delay }) => {
  const { scrollY } = useViewportScroll();
  const rotateX = useTransform(scrollY, [0, 250], [14, 0]);
  const translateY = useTransform(scrollY, [0, 250], [-24, 0]);
  const scale = useTransform(scrollY, [0, 250], [0.95, 1]);
  const isMobile = useIsMobile();

  const { observe, inView } = useInView({
    threshold: 0, // Default is 0
    // More useful options...
  });

  const [video, videoState, videoControls, videoRef] = useVideo(
    <VideoPlayer
      className="absolute rounded-[40px] inset-0 object-cover w-full h-full max-w-full mx-auto sm:max-w-lg lg:max-w-lg 2xl:max-w-xl 4xl:max-w-2xl"
      controls={false}
      autoPlay={true}
      playsInline={true}
      muted={true}
      preload="auto"
      width={"100%"}
      url={
        "https://ladder-bootcamp-media-prod.s3.amazonaws.com/movement-videos/Lauren_Meghan/110_double_arm_continous_swing.mp4"
      }
    />
  );

  const transform = useMotionTemplate`perspective(1200px) translateX(0px) translateY(${translateY}px) scale(${scale}) rotate(0deg) rotateX(${rotateX}deg) rotateY(0deg) translateZ(0px)`;
  // perspective(1200px) translateX(0px) translateY(-16.6304px) scale(0.988768) rotate(0deg) rotateX(1.68478deg) rotateY(0deg) translateZ(0px)
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: delay * 5 }}
      style={{
        transform,
      }}
      src="https://media.teams.joinladder.com/website/hero/phone.png"
      className="w-full flex center max-w-[420px] xl:max-w-xl"
    >
      <PhoneOutline
        showBrowser={false}
        height={isMobile ? 770 : 900}
        width={isMobile ? "100%" : 480}
      >
        <div className="absolute w-full top-0 z-10 flex center px-8 text-white">
          <div
            style={{
              width: 220,
              height: 42,
              borderRadius: "0 0 28px 28px",
            }}
            className="mx-auto center notch bg-neutral-900"
          >
            <div
              style={{
                boxShadow:
                  "0 1px 2px rgb(255 255 255 / 12%), inset 0 -0.5px 0 rgb(255 255 255 / 15%), inset 0 0 1px rgb(0 0 0), inset 0 2px 2px rgb(0 0 0 / 45%)",
              }}
              className="w-12 h-1 py-[.18rem] mx-auto my-1 bg-neutral-800 rounded-full"
            />
          </div>
        </div>
        <div className="w-full h-full rounded-[40px] overflow-hidden">
          {video}
          <div className="absolute rounded-br-3xl border-b border-neutral-700 top-0 w-full pt-14 pb-3 px-4 flex items-center justify-between text-white bg-neutral-800/80 backdrop-blur-md">
            <div className="w-12 h-12 relative center">
              <span className="animate-ping absolute inline-flex h-2/3 w-2/3 duration-1000 rounded-full bg-lemon opacity-75"></span>
              <img
                className="rounded-full relative border-lemon border"
                src="https://ladderteams.imgix.net/workout-coach-avatars/Body%20%26%20Bell.png?w=100"
              />
            </div>
            <Countdown />
            <div className="w-8 h-8 center">
              <img src="/assets/apple-music-icon.png" />
            </div>
          </div>
          {!isMobile ? (
            <motion.div
              animate={{ y: !inView ? 300 : 0 }}
              transition={{ type: "spring", bounce: 0.15, delay: 1 }}
              className="absolute rounded-tr-3xl border-t border-neutral-700 bottom-0 w-full pb-5 pt-3 px-4 flex items-center justify-end text-black bg-lemon"
            >
              <div className="flex flex-col text-right">
                <span className="text-base font-display">UP NEXT</span>
                <span className="text-sm font-medium">KB Goblet Squat</span>
              </div>
            </motion.div>
          ) : null}
          <div
            ref={observe}
            className="h-2 w-full absolute bottom-0 z-20"
          ></div>
        </div>
      </PhoneOutline>
    </motion.div>
  );
};

export const Hero = ({ scrollYProgessMultiplier = 1500 }) => {
  const { setIsHeroCTAInView } = useApp();
  const { variant } = useExperiment();
  const { scrollYProgress } = useViewportScroll();
  const contentY = useTransform(
    scrollYProgress,
    (value) => (value / -1) * scrollYProgessMultiplier
  );

  const { observe } = useInView({
    threshold: 0, // Default is 0
    onEnter: () => {
      setIsHeroCTAInView(true);
    },
    onLeave: () => {
      setIsHeroCTAInView(false);
    },
    // More useful options...
  });

  const isMobile = useIsMobile();
  const marginBottom = isMobile ? 150 : 300;
  const delay = 0.25;

  return (
    <section className="relative w-full h-screen">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: delay * 5 }}
        style={{
          height: `calc(100vh + ${marginBottom}px)`,
          backgroundImage: `url(https://media.teams.joinladder.com/website/hero/${
            isMobile ? "mobile/" : ""
          }${accessVariant("home.hero.bg", variant)})`,
        }}
        className={clsx(
          "absolute mt-32 lg:mt-12 inset-0 z-30 w-full h-screen bg-center bg-no-repeat bg-cover"
        )}
      ></motion.div>
      <motion.div className="relative z-40 flex flex-col items-center justify-start h-full max-w-5xl pt-4 mx-auto lg:pt-6 xl:pt-8">
        <div className={clsx("relative max-w-full px-4 lg:w-52 w-36")}>
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.1 }}
            src="/assets/apple-stars-and-reviews.svg"
            className="w-full mb-4"
            alt="womans health award"
          />
        </div>
        <motion.div
          style={{ y: contentY }}
          className="relative z-10 flex flex-col items-center justify-start mx-auto transform -translate-y-8"
        >
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: delay * 2 }}
            className="mb-4 px-4 text-2.5xl leading-8 text-center text-white uppercase lg:leading-tight md:mb-8 lg:text-5xl heading"
          >
            <Text at="home.hero.title" />
          </motion.h1>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: delay * 3 }}
            style={{ color: "#C1C1C1" }}
            className="max-w-3xl px-6 mx-auto mb-8 text-lg text-center text-white md:mb-8 md:text-xl lg:px-20"
          >
            <Text at="home.hero.description" />
          </motion.p>
          <motion.div
            ref={observe}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: delay * 4 }}
            className="px-4"
          >
            <Action
              at="home.hero.cta"
              className="px-10 py-4 text-black rounded-full cta bg-volt"
            />
          </motion.div>
          {Number(variant) === 0 || ["tiktok", "riddler"].includes(variant) ? (
            // <AnimatedPhone delay={delay} />
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: delay * 5 }}
              src="https://media.teams.joinladder.com/website/hero/phone.png"
              className="w-full max-w-lg xl:max-w-xl"
            />
          ) : null}
        </motion.div>
      </motion.div>
    </section>
  );
};
