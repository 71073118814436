import ConfettiExplosion from "@reonomy/react-confetti-explosion";
import clsx from "clsx";
import React, { useEffect } from "react";

const DURATION = 4000;

export const ConfettiComponent = React.memo(({ show, setShow }) => {
  useEffect(() => {
    if (show) {
      setTimeout(() => setShow(false), DURATION - 500);
    }
  }, [show, setShow]);

  return (
    <div
      className={clsx("absolute inset-0 z-0 center", {
        hidden: !show,
      })}
    >
      <ConfettiExplosion
        {...{
          colors: ["#fff", "#555555", "#636363"],
          force: 0.5,
          duration: DURATION,
          particleCount: 150,
          floorHeight: 1200,
          floorWidth: 1200,
        }}
      />
    </div>
  );
});
