import { RefreshIcon } from "@heroicons/react/outline";
import {
  BookmarkIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CopyIcon,
  LockClosedIcon,
  Share2Icon,
} from "@radix-ui/react-icons";
import React from "react";
import { isDev } from "~/helpers/misc";

export const PhoneOutline = ({
  children,
  id = "phone-outline",
  showBrowser = true,
  height = 850,
  width = 420,
}) => {
  return (
    <div
      id={id}
      style={{
        maxHeight: height,
        minHeight: height,
        width: width,
        minWidth: width,
        boxShadow:
          "rgb(255 255 255 / 87%) 0px 0px 2px 0px, rgb(0 0 0 / 99%) 0px 0px 10px 0px",
      }}
      className="w-full h-full relative max-w-md m-0 overflow-hidden scale-90 bg-black border-4 shadow-xl iphone border-neutral-900"
    >
      <div
        style={{ maxHeight: showBrowser ? 712 : "none", minHeight: 712 }}
        className="w-full h-full overflow-x-hidden overflow-y-scroll"
      >
        {children}
      </div>
      {showBrowser ? (
        <div className="sticky bottom-0 z-20 w-full p-2 bg-neutral-800">
          <div className="flex justify-between px-3 py-2 text-white bg-black rounded-xl">
            <span>aA</span>
            <div className="gap-1 select-all center">
              <LockClosedIcon className="w-3 h-3 text-neutral-300" />
              <span>{isDev ? "dev." : ""}joinladder.com</span>
            </div>
            <span>
              <RefreshIcon className="w-5 h-5" />
            </span>
          </div>
          <div className="flex justify-between px-3 py-4 pb-4 text-white/60">
            <ChevronLeftIcon className="w-8 h-8" />
            <ChevronRightIcon className="w-8 h-8" />
            <Share2Icon className="w-7 h-7" />
            <BookmarkIcon className="w-7 h-7" />
            <CopyIcon className="w-7 h-7" />
          </div>
          <div className="w-36 h-1 py-[.18rem] mx-auto my-1 bg-white/90 rounded-full" />
        </div>
      ) : null}
    </div>
  );
};
