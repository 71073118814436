import { Text } from "~/components/text-node";
import { useWindowSize } from "react-use";
import { useBreakpoint } from "~/lib/use-breakpoint";
import { DaysComponent } from "./days";
import { Action } from "~/components/action";

export const EachDaySectionMobile = ({ progress }) => {
  const breakpoint = useBreakpoint();
  const { width: windowWidth } = useWindowSize();

  return (
    <div
      // style={{ height: `calc(100vh - 250px)` }}
      className="container flex flex-col items-center justify-center w-full px-4 pb-48 mx-auto lg:h-screen lg:pb-0"
    >
      <div className="relative w-full mb-8">
        <div className="max-w-xl mx-auto">
          <div className="absolute inset-0 flex items-end justify-center w-full h-full"></div>
          <img
            className="relative z-10 mx-auto"
            src="https://media.teams.joinladder.com/website/phone/mobile/in-ring.png"
            alt="ladder app on an iPhone"
          />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center w-full">
        <DaysComponent progress={progress} />
        <h2 className="mt-8 text-3xl text-center text-white heading">
          <Text at="home.eachDay.title" />
        </h2>
        <p className="px-8 mt-4 text-center text-secondary">
          <Text at="home.eachDay.description" />
        </p>
        <Action
          at="home.eachDay.cta"
          event={{ action: "find_my_plan_cta_clicked" }}
          className="px-10 py-4 mt-10 text-black rounded-full cta bg-volt"
        />
      </div>
    </div>
  );
};
