import { PauseIcon, PlayIcon } from "@heroicons/react/outline";
import { motion } from "framer-motion";
import React, { useState } from "react";
import useInView from "react-cool-inview";
import { useInterval, useToggle } from "react-use";

const Item = ({ i, lastItem, onEnter, onLeave, handleLastVisible }) => {
  const { observe, inView } = useInView({
    threshold: 0.5, // Default is 0
    onEnter: ({ unobserve }) => {
      // onEnter(i)
      if (lastItem === i) {
        unobserve();
        handleLastVisible();
      }
    },
    onLeave: () => {
      // onLeave(i)
    },
  });

  return (
    <img
      ref={observe}
      alt={`ladder coach ${i}`}
      className="w-1/2 md:w-1/3 xl:w-1/4"
      src={`https://media.teams.joinladder.com/website/coaches/portraits/${i}.png`}
    />
  );
};

const ScrollingCoaches = ({
  initialItems = [0, 1, 2, 3, 4],
  speed = 0.1,
  inView,
}) => {
  const [shouldScroll, setShouldScroll] = useState(true);
  const [items, setItems] = useState(initialItems);
  const [x, setX] = useState(0);

  useInterval(
    () => {
      setX((current) => current + speed);
    },
    inView && shouldScroll ? 0.1 : null
  );

  return (
    <motion.div
      initial={{ x: 0 }}
      animate={{
        x: -x,
      }}
      className="flex space-x-3 transform scrollbars-hidden overscroll-x-contain scroll-pl-3 first:pl-3 last:pr-3 lg:space-x-6"
    >
      {items.map((item, i) => (
        <Item
          lastItem={items[items.length - 1]}
          key={i}
          i={item}
          handleLastVisible={() => {
            setItems((c) => {
              if (c.length === initialItems.length * 3) {
                setShouldScroll(false);
                return c;
              }
              return [...c, ...initialItems];
            });
          }}
        />
      ))}
    </motion.div>
  );
};

export const CoachContainer = () => {
  const [playing, togglePlaying] = useToggle(true);
  const { observe, inView } = useInView({
    threshold: 0, // Default is 0
  });

  return (
    <div className="relative z-10 min-h-screen pt-16 pb-32 lg:pb-56">
      <div
        style={{
          background: `radial-gradient(50% 50% at 50% 50%, #403C56 28.65%, rgba(64, 60, 86, 0) 100%)`,
        }}
        className="absolute inset-0 transform scale-110 -translate-y-24 rounded-full"
      />
      <div className="relative mb-12">
        <div className="max-w-5xl px-2 mx-auto space-y-6 text-center">
          <h2 className="hidden text-4xl text-center text-white lg:block lg:text-6xl heading">
            <span className="block mb-2 text-2xl lg:mb-4 lg:text-4xl">
              ONLY THE
            </span>{" "}
            BEST STRENGTH COACHES
          </h2>
          <h2 className="block text-3xl text-center text-white lg:hidden heading">
            ONLY THE BEST STRENGTH COACHES
          </h2>
        </div>
      </div>
      <div className="mb-24 overflow-hidden max-w-screen">
        <motion.div ref={observe} className="relative space-y-3 lg:space-y-6">
          <ScrollingCoaches
            initialItems={[15, 0, 1, 2, 3, 4, 6]}
            inView={inView && playing}
          />
          <ScrollingCoaches
            inView={inView && playing}
            initialItems={[8, 9, 10, 11, 12, 13, 14]}
            speed={0.175}
          />
        </motion.div>
        <div className="relative flex items-center justify-end px-4 mx-auto">
          <button
            aria-label={playing ? "pause" : "play"}
            className="p-4"
            onClick={togglePlaying}
          >
            {playing ? (
              <PauseIcon className="w-8 h-8 text-white lg:w-12 lg:h-12" />
            ) : (
              <PlayIcon className="w-8 h-8 text-white lg:w-12 lg:h-12" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
