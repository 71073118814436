import { Controller, Scene } from "react-scrollmagic";
import React, { useRef, useState } from "react";
import { EachDaySectionMobile } from "~/components/home/every-day-section/mobile";
import { useBreakpoint, useIsMobile } from "~/lib/use-breakpoint";
import { EachDaySectionDesktop } from "~/components/home/every-day-section/desktop";
import clsx from "clsx";

export const EachDaySection = () => {
  const breakpoint = useBreakpoint();
  const [isComplete, setIsComplete] = useState(false);
  const [hasCompletedExplosionComplete, setHasCompletedExplosionComplete] =
    useState(false);
  const isMobile = useIsMobile();

  return (
    <div>
      <div>
        <Controller>
          <Scene
            triggerHook={isMobile ? 0.5 : 0}
            duration={600}
            pin={!isMobile}
          >
            {(progress) => {
              if (progress > 0.85 && !hasCompletedExplosionComplete) {
                setIsComplete(true);
                setHasCompletedExplosionComplete(true);
              }
              return (
                <section>
                  <div
                    className={clsx({
                      hidden: ["sm", "md"].includes(breakpoint),
                    })}
                  >
                    <EachDaySectionDesktop
                      {...{
                        progress,
                        isComplete,
                        setIsComplete,
                      }}
                    />
                  </div>
                  <div
                    className={clsx({
                      hidden: !["sm", "md"].includes(breakpoint),
                    })}
                  >
                    <EachDaySectionMobile
                      {...{
                        progress,
                        isComplete,
                        setIsComplete,
                      }}
                    />
                  </div>
                </section>
              );
            }}
          </Scene>
        </Controller>
      </div>
    </div>
  );
};
