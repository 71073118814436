import { accessVariant, Text } from "~/components/text-node";
import { motion } from "framer-motion";
import React, { useRef, useState } from "react";

export const SaveTimeContainer = () => {
  return (
    <div className="relative pb-36 lg:pb-56 bg-basic">
      <div className="container flex flex-col items-center justify-center w-full px-4 mx-auto lg:space-x-8 lg:flex-row">
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
          className="w-full"
        >
          <motion.img
            variants={{
              offscreen: {
                y: 200,
                opacity: 0,
                rotate: -10,
              },
              onscreen: {
                y: -60,
                opacity: 1,
                rotate: 0,
                transition: {
                  type: "spring",
                  bounce: 0.2,
                  duration: 0.8,
                },
              },
            }}
            src="https://media.teams.joinladder.com/website/watch/group.png"
            className="max-w-full w-lg md:max-w-lg"
          />
        </motion.div>
        <div className="w-full max-w-xl space-y-8">
          <h2 className="text-5xl text-center text-black uppercase lg:text-left lg:text-6xl heading">
            <span className="block text-lg lg:text-4xl">
              Save time &amp; train
            </span>
            <span className="block">on your schedule</span>
          </h2>
          <p className="px-6 text-lg text-center lg:text-xl lg:text-left text-charcoal lg:pr-20 lg:pl-0">
            <Text at="home.watch.description" />
          </p>
        </div>
      </div>
    </div>
  );
};
