import { Controller, Scene } from "react-scrollmagic";

export const Featured = () => {
  // given a number between 0 and 1, get the opposit number
  const getOpposite = (num) => 1 - num;
  return (
    <Controller>
      <Scene triggerHook={1} duration={"100%"} pin={false}>
        {(progress) => {
          return (
            <section
              style={{
                background: `linear-gradient(180deg, rgba(14, 14, 14, 0) 0%, #0E0E0E 25%)`,
              }}
              className="container mt-[150px] lg:mt-[300px] relative z-40 flex flex-col items-center w-full px-6 mx-auto"
            >
              <img
                alt="ladder logo"
                className="mx-auto"
                src="/assets/logo/big-faded.svg"
                style={{ opacity: getOpposite(progress) }}
              />
              <div className="space-y-8 transform -translate-y-8 lg:-translate-y-16">
                <h3 className="text-sm text-center text-white heading md:text-xl lg:text-1xl">
                  FEATURED ON
                </h3>
                <img
                  alt="featured logos"
                  className="mx-auto lg:px-8"
                  src="/assets/logo/featured.svg"
                />
              </div>
            </section>
          );
        }}
      </Scene>
    </Controller>
  );
};
