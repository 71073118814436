import { motion } from "framer-motion";
import React from "react";
import useInView from "react-cool-inview";
import { Action } from "~/components/action";
import { useBreakpoint, useIsMobile } from "~/lib/use-breakpoint";

const MockCoachMessage = ({ image, from, date, message }) => {
  return (
    <div className="flex w-64 space-x-2 text-white md:w-80">
      <div className="items-start center">
        <img
          className="w-16 rounded-full md:w-24"
          src={`/assets/coaches/${image}`}
          alt={`ladder coach ${image}`}
        />
      </div>
      <div className="space-y-2">
        <div className="flex justify-start space-x-2 center">
          <h1 className="text-sm capitalize text-shadow-sm md:text-base heading">
            {from}
          </h1>
          <span className="text-xs text-shadow-sm">{date}</span>
        </div>
        <div
          // style={{ backgroundColor: "#575757" }}
          className="p-4 text-xs text-white rounded-lg text-shadow-sm md:text-base bg-messageGray bg-opacity-60 backdrop-blur-lg"
        >
          {message}
        </div>
      </div>
    </div>
  );
};
const MockMemberMessage = ({ image, from, date, message }) => {
  return (
    <div className="flex w-64 ml-auto space-x-2 text-white md:w-80">
      <div className="space-y-2">
        <div className="flex justify-end space-x-2 center">
          <h1 className="text-sm capitalize text-shadow-sm md:text-base heading">
            {from}
          </h1>
          <span className="text-xs text-shadow-sm">{date}</span>
        </div>
        <div
          style={{
            background: `linear-gradient(96.42deg, rgba(255, 255, 255, 0.75) 67.84%, rgba(242, 255, 119, 0.75) 123.79%)`,
          }}
          className="p-4 text-xs text-black rounded-lg md:text-base bg-messageGray bg-opacity-60 backdrop-blur-lg"
        >
          {message}
        </div>
      </div>
      <div className="items-start center">
        <img
          className="w-16 rounded-full md:w-24"
          src={`https://media.teams.joinladder.com/website/coaches/chat/${image}`}
          alt={`ladder member ${image}`}
        />
      </div>
    </div>
  );
};

const AnimateIn = ({ children }) => {
  const { observe, unobserve, inView, scrollDirection, entry } = useInView({
    threshold: 1, // Default is 0
    onEnter: ({ observe, unobserve }) => {
      unobserve();
    },
    // onLeave: ({ observe, unobserve }) => {
    //   if (scrollDirection.vertical === "up") {
    //     observe();
    //   }
    // },
    // More useful options...
  });

  return (
    <motion.div
      ref={observe}
      initial={"hidden"}
      animate={inView ? "visible" : "hidden"}
      variants={{
        hidden: { opacity: 0, y: 100 },
        visible: { opacity: 1, y: 0, delay: 1 },
      }}
      className="relative"
    >
      {children}
    </motion.div>
  );
};

export const TrainingContainer = () => {
  const breakpoint = useBreakpoint();
  const isMobile = useIsMobile();

  return (
    <div
      style={{ maxHeight: "1100px" }}
      className="relative z-20 lg:z-10 lg:h-screen"
    >
      <div
        style={{
          maxHeight: "1100px",
          minHeight: "680px",
          backgroundImage: `url(https://media.teams.joinladder.com/website/phone/in-hand${
            isMobile ? "-mobile" : ""
          }.png)`,
          height: ["sm", "md"].includes(breakpoint)
            ? `calc(100vh - 240px)`
            : "",
        }}
        className="bottom-0 left-0 z-20 w-full max-w-2xl mx-auto transform -translate-y-12 bg-center bg-no-repeat bg-cover md:h-screen lg:bg-center-bottom md:bg-contain lg:max-w-4xl lg:-translate-x-16 lg:absolute lg:w-2/3"
      >
        <div className="relative z-40 flex flex-col w-full h-full pt-16 pl-4 pr-4 space-y-12 transform md:justify-center lg:pb-36 xl:pb-48 lg:pl-24 xl:pl-36 md:pl-12 lg:pr-16 md:space-y-16 lg:translate-x-16">
          <div className="">
            <AnimateIn>
              <MockCoachMessage
                image="neen.png"
                from="Coach Neen"
                date="Today @ 3:24 pm"
                message="Nice job! To help avoid hurting yourself, can you send me a video of your form?"
              />
            </AnimateIn>
          </div>
          <div className="">
            <AnimateIn>
              <MockMemberMessage
                image="member.png"
                from="Me"
                date="Today @ 3:31 pm"
                message="Feeling strong and efficient. Here’s the complex for today’s workout!"
              />
            </AnimateIn>
          </div>
          <div className="">
            <AnimateIn>
              <MockCoachMessage
                image="neen.png"
                from="Coach Neen"
                date="Today @ 3:45 pm"
                message="Boom! Make sure you keep your feet shoulder width apart. Explode with your hips!"
              />
            </AnimateIn>
          </div>
        </div>
        <div
          style={{
            background: `linear-gradient(180deg, rgba(14, 14, 14, 0) 24.63%, #0E0E0E 71.23%)`,
          }}
          className="absolute bottom-0 z-30 w-full h-48"
        />
      </div>
      <div className="container relative flex flex-col-reverse items-center justify-center h-full px-2 mx-auto -mt-24 lg:mt-0 lg:flex-row">
        <div className="w-full lg:w-2/3"></div>
        <div className="w-full mb-24 space-y-8 lg:w-1/3">
          <h2 className="text-5xl text-center text-white uppercase lg:text-6xl lg:text-left heading">
            <span className="text-lg lg:text-4xl">Learn from</span> <br /> the
            best
          </h2>
          <p className="px-6 text-lg text-center text-white opacity-60 lg:text-left lg:pr-20 lg:pl-0">
            Answers to all your questions, and 1-on-1 help from your coach when
            you need it.
          </p>
          <div className="flex mt-10 center lg:block lg:mt-110">
            <Action
              at="home.training.cta"
              className="block px-10 py-4 mx-auto text-black rounded-full cta bg-volt lg:mx-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
